import {useRef, useState} from 'react';
import {useParams, useHistory, useRouteMatch} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {QueryFunctionContext, useQuery} from 'react-query';
import {User} from 'types';

//@ts-ignore
import html2pdf from 'html2pdf.js';

import api from 'api';

interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address_line_1: string;
  city: string;
  zipcode: string;
  state: string;
  birthday: string;
  allergies: string;
  longitude: number;
  latitude: number;
}

type Medication = {
  directions: string;
  indication: string;
  medication: string;
  quantity: number;
  type: 'Rx' | 'OTC';
  id: number;
};

async function getMedications({queryKey}: QueryFunctionContext<string[]>) {
  const [, page] = queryKey;
  const {data} = await api.get<Medication[]>(
    `/prescribers/meds-kit-medications`,
    {
      params: {
        limit: 20,
        offset: +page * 20,
      },
    }
  );

  return data;
}

// async function getEmployeeById({queryKey}: QueryFunctionContext) {
//   const [, employeeId] = queryKey;

//   if (!employeeId) return undefined;

//   const {data} = await api.get<User>(`/users/${employeeId}`);

//   return data;
// }

function useEmployeeForm() {
  const {employeeId} = useParams<{employeeId: string}>();
  const {push} = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();

  const [submitting, setSubmitting] = useState(false);

  const [currentPage] = useState(parseInt(params.page || '1'));
  const {data: medications, isLoading} = useQuery(
    ['medications', String(currentPage - 1 || 0)],
    getMedications
  );

  const [selectedMeds, setSelectedMeds] = useState<number[]>([]);

  const contentRef = useRef<any>();

  const {
    handleSubmit,
    register,
    formState: {errors},
    setValue,
    getValues,
  } = useForm<FormValue>();

  async function submit(data: FormValue) {
    setSubmitting(true);
    const birth = data.birthday.split('/');
    const body = {
      user: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        birthday: birth[2] + '-' + birth[0] + '-' + birth[1],
        allergies: data.allergies,
      },
      address: {
        address_line_1: data.address_line_1,
        city: data.city,
        zipcode: data.zipcode,
        state: data.state,
        latitude: data.latitude,
        longitude: data.longitude,
      },
      meds_kit: medications
        ?.filter((item) => !selectedMeds.includes(item.id))
        .map((item) => item.id),
      file: '',
    };

    const element = contentRef.current;
    let opt = {
      margin: 0.2,
      filename: 'prescribe.pdf',
      image: {
        type: 'jpeg',
        quality: 0.99,
      },
      html2canvas: {
        scale: 2,
        logging: true,
        dpi: 192,
        letterRendering: true,
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .output('datauristring')
      .then(async (data: string) => {
        body.file = data;
        try {
          await (employeeId
            ? api.patch<User>(`/users/${employeeId}/`, body)
            : api.post<User>('/prescribers/create-prescription/', body));

          setSubmitting(false);

          push('/prescriber/prescriptions');
        } catch (error) {
          setSubmitting(false);
        }
      });
  }

  const returnData = (data: Medication[] | undefined) => {
    if (data) {
      return data.map((item) => ({
        ...item,
        checkbox: (
          <input
            type="checkbox"
            onChange={(event) => {
              setSelectedMeds((pr) =>
                pr.includes(Number(event.target.value))
                  ? pr.filter((item) => item !== Number(event.target.value))
                  : [...pr, Number(event.target.value)]
              );
            }}
            id={String(item.id)}
            name={item.medication}
            value={item.id}
            checked={!selectedMeds.includes(item.id)}
          />
        ),
      }));
    }
    return [];
  };

  return {
    contentRef,
    employeeId,
    submitting,
    errors,
    register,
    getValues,
    setValue,
    isLoading,
    medications: returnData(medications),
    handleSubmit: handleSubmit(submit),
  };
}

export default useEmployeeForm;
