import {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {QueryFunctionContext, useQuery} from 'react-query';

import ViewDetails from 'components/ViewDetails';

import {ListResponse, User} from 'types';
import api from 'api';
import {getFullName} from 'helpers/user';

import localStyles from './Prescribers.module.scss';
import useSort from 'hooks/useSort';
import ReactSwitch from 'react-switch';
import toast from 'react-hot-toast';
import {get} from 'lodash';

async function getEmployees({queryKey}: QueryFunctionContext) {
  const [, role, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<User>>('/users/', {
    params: {role, order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function useEmployees() {
  const {url} = useRouteMatch();
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_employe`) || ''
  );
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const {data: employees, isLoading} = useQuery(
    ['prescribers', 'prescriber', order, keyWord],
    getEmployees
  );

  const data = useMemo(
    function () {
      if (!employees) return [];

      return employees.results
        .filter((employee) => employee.role !== 'customer')
        .map((employee) => ({
          ...employee,
          name: getFullName(employee),
          role: <span className={localStyles.role}>{employee.role}</span>,
          active:
            employee.role !== 'management' ? (
              <PrescribeStatus id={employee.id} active={employee.is_active} />
            ) : null,
          view: <ViewDetails to={`${url}/${employee.id}`} />,
        }));
    },
    [employees, url]
  );

  return {
    data,
    isLoading,
    sortBy,
    sortOrder,
    keyWord,
    setKeyWord,
    onSort,
  };
}

const PrescribeStatus = ({active, id}: {active: boolean; id: number}) => {
  const [checked, setChecked] = useState(active);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  const onChange = () => {
    setChecked((pr) => !pr);
    api
      .patch<User>(`/users/${id}/`, {
        is_active: !checked,
      })
      .then(() => {
        toast.success('Status updated successfully');
      })
      .catch((err) => {
        toast.error(
          get(err, 'response.data.message', '') || 'Failed to update status'
        );
        setChecked((pr) => !pr);
      });
  };

  return (
    <div
      style={{
        paddingRight: 60,
      }}
    >
      <label>
        <ReactSwitch
          onColor="#0072b3"
          onChange={onChange}
          handleDiameter={17}
          height={20}
          width={40}
          checked={checked}
        />
      </label>
    </div>
  );
};

export default useEmployees;
