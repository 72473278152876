// import Button from 'components/Button';
import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import useEmployees from './usePrescribers';

import styles from '../Settings.module.scss';
import {DataTableSchemaItem} from 'types';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Prescriber Name',
    sortable: true,
  },
  {
    dataKey: 'phone',
    header: 'Phone Number',
    sortable: true,
  },
  {
    dataKey: 'npi_no',
    header: 'NPI',
    sortable: true,
  },
  {
    dataKey: 'organisation',
    header: 'Organization',
  },
  {
    dataKey: 'active',
    header: 'Status',
    align: 'right',
  },
  {
    dataKey: 'created_at',
    header: 'Date',
    align: 'right',
    sortable: true,
  },
  {dataKey: 'view', header: ''},
];

function Employees() {
  const {data, isLoading, sortBy, sortOrder, onSort, keyWord, setKeyWord} =
    useEmployees();

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Prescribers</Typography>
        {/* <Button to="/settings/employees/new" component="a" color="green">
          New Employee
        </Button> */}
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_employe`, val.target.value);
            }}
            size="sm"
          />
        </ListActionsWrapper>
        <DataTable
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          selectable={false}
          data={data}
          schema={SCHEMA}
          loading={isLoading}
        />
      </ListWrapper>
    </div>
  );
}

export default Employees;
