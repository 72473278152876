import {Controller} from 'react-hook-form';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';
import ClickablePagination from 'components/ClickablePagination';
import states from 'constants/states';
import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useEmployeeForm';

import styles from './EmployeeForm.module.scss';
import roles from 'constants/roles';
import {Fragment, useCallback, useMemo, useState} from 'react';
import useDeleteEmployee from './useDeleteEmployee';
import {useHistory} from 'react-router-dom';
import EyeIcon from './icons/eye.svg';
import EyeDisabledIcon from './icons/eye-disable.svg';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import ReactSwitch from 'react-switch';
import ListTabs from 'pages/UserForm/ListTabs';
import DataTable from 'components/DataTable';
import {DataItem, DataTableSchemaItem} from 'types';
import {getFullName} from 'helpers/user';
import ViewDetails from 'components/ViewDetails';
import StatusBadge from 'components/StatusBadge';
import {mapMMStatusText, mapMMSubStatusColor} from 'constants/mappings';

const MM_SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {dataKey: 'customer', header: 'Patient', sortable: true, index: 1},
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false, index: 3},
  {dataKey: 'amount_due', header: 'Amount', sortable: false, index: 4},
  {
    dataKey: 'id',
    header: 'Order #',
    sortable: true,
    index: 5,
  },
];

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'name',
    header: 'Name',
    sortable: true,
  },

  {
    dataKey: 'phone',
    header: 'Phone',
  },
  {
    dataKey: 'email',
    header: 'Email',
  },
  {
    dataKey: 'total_prescriptions',
    header: 'Total prescriptions',
    align: 'center',
    sortable: true,
  },
  {
    dataKey: 'created_at',
    header: 'Date',
    sortable: true,
  },
  {
    dataKey: 'view',
    header: '',
  },
];

function EmployeeForm() {
  const {
    role,
    employeeId,
    options,
    submitting,
    errors,
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    status,
    setStatus,
    prescriptions,
    prescriptionsLoading,
    customers,
    customersLoading,
    totalPages,
    currentPage,
    setCurrentPage,
  } = useEmployeeForm();
  const history = useHistory();
  const {deleteEmployee, isDeleting} = useDeleteEmployee();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const tabs = useMemo(function () {
    return [
      {title: 'Details', value: ''},
      {title: 'Prescriptions', value: 'prescriptions'},
      {title: 'Patients', value: 'patients'},
    ];
  }, []);

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);

      history.replace(
        role === 'Prescriber' ? '/settings/prescribers' : '/settings/employees'
      );
    },
    [deleteEmployee, role, history]
  );

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      history.push(`/orders/${row.id}`);
    },
    [history]
  );

  const users = useMemo(
    function () {
      return customers
        ? customers.map((u) => ({
            ...u.user,
            ...u,
            name: getFullName(u.user),
            view: <ViewDetails to={`/settings/users/${u.id}/edit`} />,
          }))
        : [];
    },
    [customers]
  );

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {employeeId
            ? role === 'Prescriber'
              ? 'Edit Prescriber'
              : 'Edit Employee'
            : 'New Employee'}
        </Typography>
        <FormActionGroup>
          {!!employeeId && (
            <Button
              onClick={() => handleDeleteEmployee(+employeeId)}
              color="danger"
              loading={isDeleting}
            >
              Delete
            </Button>
          )}
          <Button
            to={
              role === 'Prescriber'
                ? '/settings/prescribers'
                : '/settings/employees'
            }
            component="a"
            color="gray"
          >
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      {role === 'Prescriber' && (
        <ListTabs
          tab="employee_tab"
          onChange={setStatus}
          activeTabValue={status}
          tabs={tabs}
        />
      )}
      {status === 'prescriptions' && (
        <DataTable
          schema={MM_SCHEMA}
          data={prescriptions.map((item) => ({
            ...item,
            status: (
              <StatusBadge
                color={mapMMSubStatusColor[item.status as 'completed']}
              >
                {mapMMStatusText[item.status as 'completed']}
              </StatusBadge>
            ),
            amount_due: `$${item.amount_due?.toFixed(2)}`,
          }))}
          // sortBy={sortBy}
          // sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          // onSort={onSort}
          loading={prescriptionsLoading}
        />
      )}
      {status === 'patients' && (
        <DataTable data={users} schema={SCHEMA} loading={customersLoading} />
      )}
      {(status === 'patients' || status === 'prescibers') && totalPages > 1 && (
        <ClickablePagination
          page={currentPage}
          total={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      {status === '' && (
        <ListWrapper>
          <CardContent px="md" pt="none" pb="md">
            {role === 'Prescriber' && (
              <TextField
                label="Organization Name"
                placeholder="Organization Name"
                {...register('organisation', {required: 'Required'})}
                error={errors.organisation?.message}
              />
            )}
            <FormGroup columns={2}>
              <TextField
                label="First Name"
                placeholder="First Name"
                {...register('first_name', {required: 'Required'})}
                error={errors.first_name?.message}
              />
              <TextField
                label="Last Name"
                placeholder="Last Name"
                {...register('last_name', {required: 'Required'})}
                error={errors.last_name?.message}
              />
            </FormGroup>

            <FormGroup columns={2}>
              <TextField
                label="Email Address"
                placeholder="Email Address"
                {...register('email', {
                  required: 'Required',
                  validate: validateEmail,
                })}
                error={errors.email?.message}
              />
              <TextField
                label="Phone Number"
                placeholder="Phone Number"
                {...register('phone', {
                  required: 'Required',
                })}
                error={errors.phone?.message}
              />
            </FormGroup>
            {role === 'Prescriber' && (
              <FormGroup columns={2}>
                <TextField
                  label="NPI Number"
                  placeholder="NPI Number"
                  {...register('npi_no', {
                    required: 'Required',
                  })}
                  error={errors.npi_no?.message}
                />
                <GooglePlaceAutoComplete
                  defaultValue={
                    getValues().address_line_1
                      ? `${getValues().address_line_1}, ${getValues().city}, ${
                          getValues().state
                        }, ${getValues().zipcode}`
                      : ''
                  }
                  label="Your Address"
                  placeholder="Your Address"
                  onSelectPlace={(data) => {
                    setValue('address_line_1', data.addressLine1);
                    setValue('city', data.city);
                    setValue('zipcode', data.zipcode);
                    setValue('state', data.state);
                    setValue('latitude', data.lat);
                    setValue('longitude', data.lon);
                  }}
                  error={errors.address_line_1?.message}
                  options={{
                    types: ['address'],
                  }}
                />
              </FormGroup>
            )}

            {/* <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register('address_line_1')}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register('address_line_2')}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register('city')}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register('zipcode')}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              render={({field, fieldState}) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup> */}

            <Typography className={styles.header} variant="h3">
              Account
            </Typography>
            {!!role && !employeeId && (
              <div className={styles.account_warning}>
                Please call the {role} and inform them of their login
                information
                {role === 'Driver'
                  ? 'and instructions to download the driver app'
                  : '.'}
              </div>
            )}

            <FormGroup columns={3}>
              <TextField
                label="Username"
                placeholder="Username"
                {...register(
                  'username',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.username?.message}
              />
              <div style={{position: 'relative'}}>
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  {...register(
                    'password',
                    !employeeId
                      ? {
                          required: 'Required',
                        }
                      : {}
                  )}
                  error={errors.password?.message}
                />
                <img
                  onClick={() => setShowPassword((pr) => !pr)}
                  className={styles.eyeIcon}
                  src={showPassword ? EyeIcon : EyeDisabledIcon}
                  alt="eye"
                />
              </div>
              <div style={{position: 'relative'}}>
                <TextField
                  label="Password Confirmation"
                  type={showPassword1 ? 'text' : 'password'}
                  placeholder="Password Confirmation"
                  {...register(
                    'password_confirmation',
                    !employeeId
                      ? {
                          required: 'Required',
                        }
                      : {}
                  )}
                  error={errors.password_confirmation?.message}
                />
                <img
                  onClick={() => setShowPassword1((pr) => !pr)}
                  className={styles.eyeIcon}
                  src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                  alt="eye"
                />
              </div>
            </FormGroup>

            {role === 'Prescriber' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}
              >
                <p>Status:</p>
                <label>
                  <ReactSwitch
                    onColor="#0072b3"
                    onChange={(val) => setValue('is_active', val)}
                    handleDiameter={22}
                    height={26}
                    width={45}
                    checked={watch('is_active')}
                  />
                </label>
              </div>
            )}

            <FormGroup columns={1}>
              <Controller
                name="role"
                control={control}
                rules={{required: 'Required'}}
                render={({field}) => (
                  <SelectField
                    label="Employee Type"
                    placeholder="Employee Type"
                    options={roles}
                    {...field}
                  />
                )}
              />
            </FormGroup>

            {role === 'Driver' && (
              <Fragment>
                <Typography className={styles.header} variant="h3">
                  If Driver
                </Typography>
                <FormGroup columns={1}>
                  <Controller
                    name="vehicle"
                    control={control}
                    render={({field}) => (
                      <SelectField
                        label="Assigned truck"
                        placeholder="No assigned truck"
                        options={options}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>

                <div className={styles.section_header}>Driver’s license</div>

                <FormGroup>
                  {/*<FileUpload*/}
                  {/*  label="Please upload both sides of the document"*/}
                  {/*  onChange={handleFileUpload}*/}
                  {/*  files={license_files}*/}
                  {/*/>*/}

                  <div>
                    <FormGroup columns={2}>
                      <TextField
                        label="Document Number"
                        placeholder="S111-123-45-567-8"
                        error={errors.document_number?.message}
                        {...register(
                          'document_number',
                          !employeeId ? {required: 'Required'} : {}
                        )}
                      />

                      <Controller
                        name="document_state"
                        control={control}
                        rules={!employeeId ? {required: 'Required'} : {}}
                        render={({field, fieldState}) => (
                          <SelectField
                            label="Document State"
                            placeholder="Select a state"
                            options={states}
                            error={fieldState.error?.message}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Controller
                        name="issue_state"
                        control={control}
                        rules={!employeeId ? {required: 'Required'} : {}}
                        render={({field, fieldState}) => (
                          <SelectField
                            label="Issue state"
                            placeholder="Select a state"
                            options={states}
                            error={fieldState.error?.message}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Fragment>
            )}
          </CardContent>
        </ListWrapper>
      )}
    </form>
  );
}

export default EmployeeForm;
