import useAuth from 'hooks/useAuth';

import Logo from 'components/Logo';
import Spinner from 'components/Spinner';

import AuthApp from 'app/AuthApp';
import MainApp from 'app/MainApp';
import PaymentForm from 'pages/PaymentForm';

import 'app/App.scss';
import {useEffect, useState} from 'react';

function Loading() {
  return (
    <div className="content --full-width --center --column">
      <Logo />
      <Spinner />
    </div>
  );
}

function useApp() {
  const {loading, currentUser} = useAuth();
  const [paymentId, setPaymentId] = useState('');

  useEffect(() => {
    if (window.location.pathname.endsWith('/payment')) {
      const id = window.location.href.split('id=')[1];
      setPaymentId(id);
    }
  }, []);

  if (paymentId) {
    return <PaymentForm id={paymentId} />;
  }

  if (loading) return <Loading />;

  if (currentUser) {
    return <MainApp />;
  } else {
    return <AuthApp />;
  }
}

function App() {
  const app = useApp();
  return <div className="App">{app}</div>;
}

export default App;
