import ReactGoogleAutocomplete, {
  ReactGoogleAutocompleteInputProps,
} from 'react-google-autocomplete';
import {TypeGooglePlaceDetail} from './types';
import styles from './GooglePlaceAutoComplete.module.scss';
import {extractAddressFromPlaceDetails} from './lib/google-place-helpers';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import classNames from 'classnames';

interface IGooglePlaceAutoComplete {
  onSelectPlace: (value: TypeGooglePlaceDetail) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  wrapperClass?: string;
}
export const GooglePlaceAutoComplete = (
  props: IGooglePlaceAutoComplete & ReactGoogleAutocompleteInputProps
) => {
  const GOOGLE_API_KEY = props.apiKey || process.env.REACT_APP_GOOGLE_API_KEY;

  const onSelectPlace = (place: any) => {
    console.log(place);
    const detailedAddresses = extractAddressFromPlaceDetails(place);
    props.onSelectPlace(detailedAddresses);
  };

  return (
    <FieldWrapper
      className={props.wrapperClass}
      label={props.label}
      error={props.error}
    >
      <ReactGoogleAutocomplete
        {...props}
        apiKey={GOOGLE_API_KEY}
        libraries={['places']}
        onPlaceSelected={onSelectPlace}
        className={classNames(
          styles.google_place_auto_complete,
          props.error && styles.error
        )}
        language="en"
      />
    </FieldWrapper>
  );
};
