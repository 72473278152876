import api from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Typography from 'components/Typography';
import {get} from 'lodash';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import styles from './Requests.module.scss';

export type RefillProps = {
  open: (id: number) => void;
};

type Props = {
  refetch: () => void;
};
const RefillHandler: ForwardRefRenderFunction<RefillProps, Props> = (
  {refetch},
  ref
) => {
  const [visible, setVisible] = useState(false);

  const id = useRef(0);

  useImperativeHandle(ref, () => ({
    open: (prescription_id: number) => {
      id.current = prescription_id;
      setVisible(true);
    },
    close: () => setVisible(false),
  }));

  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    api
      .post(`/requests/refill/${id.current}/`)
      .then(() => {
        refetch();
        setVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(get(err, 'response.data.message', 'Something went wrong'));
      });
  };

  return (
    <Modal
      isOpen={visible}
      className={styles.modal}
      showCloseButton={false}
      onClose={() => setVisible(false)}
      onRequestClose={() => setVisible(false)}
    >
      <Typography style={{marginBottom: 10}} component="p" variant="h2">
        Refill
      </Typography>
      <Typography component="h3" variant="h3">
        Do you want to refill this prescription?
      </Typography>
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'flex-end',
          marginTop: 40,
        }}
      >
        <Button loading={loading} onClick={submitHandler} color="blue">
          Refill
        </Button>
        <Button
          onClick={() => {
            setVisible(false);
          }}
          color="gray"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default forwardRef(RefillHandler);
