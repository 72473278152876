import {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import Card from 'components/Card';
import Button from 'components/Button';
import TextField from 'components/TextField';
import CardContent from 'components/CardContent';
import Typography from 'components/Typography';

import {validateEmail} from 'utils/validators';
import logo from 'components/Sidebar/images/logo.svg';
import styles from './Signup.module.scss';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import api from 'api';
import FormGroup from 'components/FormGroup';
import {get} from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
// import useAuth from 'hooks/useAuth';
// import successImg from '../PaymentForm/payment_success_icon.png';

type Address = {
  address_line_1: string;
  city: string;
  zipcode: string;
  state: string;
  latitude: number;
  longitude: number;
};

interface FormValues {
  organisation: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  npi_no: string;
  address: Address;
  password: string;
  passwordConfirmation: string;
}

function Signup() {
  const [loading, setLoading] = useState(false);
  // const {login} = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {errors},
  } = useForm<FormValues>();
  const password = watch('password', '');
  // const history = useHistory();
  const [success, setSuccess] = useState(false);

  async function submit(data: FormValues) {
    setLoading(true);

    try {
      await api.post('/users/', {
        ...data,
        username: data.first_name,
        role: 'prescriber',
      });
      // history.push('/login');
      setSuccess(true);
      // await login(data.email, data.password);
    } catch (err) {
      const message = get(
        err,
        'response.data.error_message',
        'Something went wrong'
      );
      toast.error(message);
      // setStatus('error');
    } finally {
      setLoading(false);
    }
  }

  if (success) {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            marginBottom: 40,
            marginTop: 'auto',
          }}
        >
          <img
            className={styles.logo}
            style={{height: 150}}
            src={logo}
            alt="Logo"
          />
        </div>

        <Card className={styles.root}>
          <CardContent style={{textAlign: 'center'}}>
            <Typography
              style={{marginBottom: 40, color: '#ff3b24', fontWeight: '600'}}
              variant="h1"
              textAlign="center"
            >
              PENDING
            </Typography>
            {/* <img className={styles.successImg} src={successImg} alt="Success" /> */}
            <Typography variant="h2" textAlign="center">
              Thank You for Registering!
            </Typography>
            <Typography style={{marginTop: 20}} variant="h3" textAlign="center">
              A MEDS staff member will review your application and verify your
              profile.
            </Typography>
            <Typography
              style={{marginTop: 5, marginBottom: 40}}
              variant="h3"
              textAlign="center"
            >
              Upon approval, you will recieve an email and text message.
            </Typography>
            {/* <div className={styles.signup}>
              <Typography style={{fontSize: 16}} variant="p">
                After approve you can{' '}
                <Link
                  style={{fontSize: 16, textDecorationLine: 'underline'}}
                  className={styles.link}
                  to="/login"
                >
                  sign in
                </Link>
              </Typography>
            </div> */}
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <Card className={styles.root}>
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            marginBottom: 40,
            marginTop: 'auto',
          }}
        >
          <img
            className={styles.logo}
            style={{height: 150}}
            src={logo}
            alt="Logo"
          />
        </div>
        <Typography variant="h2" textAlign="center">
          Sign Up as Prescriber
        </Typography>
        <Fragment>
          <form onSubmit={handleSubmit(submit)}>
            <TextField
              label="Organization name"
              placeholder="Organization name"
              type="text"
              error={errors.organisation?.message}
              {...register('organisation', {
                required: 'Required',
              })}
            />
            <FormGroup columns={2}>
              <TextField
                label="First name"
                placeholder="First name"
                type="name"
                error={errors.first_name?.message}
                {...register('first_name', {
                  required: 'Required',
                })}
              />
              <TextField
                label="Last name"
                placeholder="Last name"
                type="name"
                error={errors.last_name?.message}
                {...register('last_name', {
                  required: 'Required',
                })}
              />
            </FormGroup>
            <FormGroup columns={2}>
              <TextField
                label="Email"
                placeholder="Email"
                type="email"
                error={errors.email?.message}
                {...register('email', {
                  required: 'Required',
                  validate: validateEmail,
                })}
              />
              <TextField
                label="Phone"
                placeholder="Phone"
                type="phone"
                error={errors.phone?.message}
                {...register('phone', {
                  required: 'Required',
                })}
              />
            </FormGroup>
            <FormGroup columns={2}>
              <TextField
                label="NPI"
                placeholder="NPI"
                type="text"
                error={errors.npi_no?.message}
                {...register('npi_no', {
                  required: 'Required',
                })}
              />
              <GooglePlaceAutoComplete
                label="Office Address"
                placeholder="Office Address"
                error={errors.address?.message}
                {...register('address', {
                  required: 'Required',
                })}
                onSelectPlace={(placeDetail) => {
                  setValue('address', {
                    address_line_1: placeDetail.addressLine1,
                    city: placeDetail.city,
                    zipcode: placeDetail.zipcode,
                    state: placeDetail.state,
                    latitude: placeDetail.lat,
                    longitude: placeDetail.lon,
                  });
                }}
                options={{
                  types: ['address'],
                }}
              />
            </FormGroup>
            <FormGroup columns={2}>
              <TextField
                label="Password"
                type="password"
                placeholder="Password"
                error={errors.password?.message}
                {...register('password', {
                  required: 'Required',
                  minLength: {value: 6, message: 'Too short'},
                })}
              />
              <TextField
                label="Confirm password"
                type="password"
                placeholder="Confirm password"
                error={errors.passwordConfirmation?.message}
                {...register('passwordConfirmation', {
                  required: 'Required',
                  validate: (value) =>
                    value === password || 'The passwords do not match',
                })}
              />
            </FormGroup>

            <div className={styles.button_container}>
              <Button type="submit" loading={loading}>
                Sign up
              </Button>
            </div>
          </form>
          <div className={styles.signup}>
            <Typography variant="p">
              Already have an account?{' '}
              <Link className={styles.link} to="/login">
                Sign in
              </Link>
            </Typography>
          </div>
        </Fragment>
      </CardContent>
      <Toaster />
    </Card>
  );
}

export default Signup;
