import {memo, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import cn from 'classnames';

import styles from './ListTabs.module.scss';

interface Tab {
  title: string;
  path?: string;
  value: string; // must be mandatory
}

interface Props {
  className?: string;
  tabs: Tab[];
  tab: 'user_tab' | 'employee_tab';
  activeTabValue?: string;
  onChange: (nextTabValue: string) => void;
}

function ListTabs({className, tabs, tab, activeTabValue, onChange}: Props) {
  useEffect(() => {
    sessionStorage.setItem(tab, activeTabValue || '');
  }, [activeTabValue, tab]);

  return (
    <div className={cn(styles.root, className)}>
      {tabs.map((tab, index) => {
        if (tab.path) {
          return (
            <NavLink
              to={tab.path}
              className={styles.tab}
              activeClassName={styles['--active']}
              exact
            >
              {tab.title}
            </NavLink>
          );
        }
        return (
          <span
            key={tab.title}
            className={cn(styles.tab, {
              [styles['--active']]: tab.value === activeTabValue,
            })}
            onClick={() => {
              onChange(tab.value);
            }}
          >
            {tab.title}
          </span>
        );
      })}
    </div>
  );
}

export default memo(ListTabs);
