import DataTable from 'components/DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';

import {DataTableSchemaItem} from 'types';
import Modal from 'components/Modal';
import Button from 'components/Button';

import styles from './InstorePickups.module.scss';
import useInstorePickups from './useInstorePickups';

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'New Leaf Order Number'},
  {dataKey: 'created_at', header: 'Created At'},
  {dataKey: 'customer', header: 'Patient'},
  {dataKey: 'status', header: 'Delivery Status'},
  {dataKey: 'confirm', header: '', align: 'right'},
];

const tabs = [
  {title: 'Active', value: 'pickup'},
  {title: 'Completed', value: 'delivered,canceled'},
];

function InstorePickups() {
  const {
    data,
    isLoading,
    isConfirmOpen,
    isConfirming,
    activeTab,
    onChangeTab,
    closeConfirm,
    confirm,
  } = useInstorePickups();

  return (
    <>
      <Typography variant="h2">All deliveries</Typography>
      <ListWrapper>
        <ListTabs
          tabs={tabs}
          activeTabValue={activeTab}
          onChange={onChangeTab}
        />
        <ListActionsWrapper>
          <SearchInput placeholder="Search" />
        </ListActionsWrapper>
        <DataTable
          loading={isLoading}
          data={data}
          schema={SCHEMA}
          selectable={false}
        />
      </ListWrapper>

      <Modal isOpen={isConfirmOpen} onClose={closeConfirm}>
        <div className={styles.modal__content}>
          <Typography className={styles.modal__title} variant="h1">
            Confirm pickup
          </Typography>
          <Typography variant="h4">Confirming patient pickup</Typography>
        </div>

        <div className={styles.modal__actions} onClick={closeConfirm}>
          <Button color="outlined" size="lg">
            Cancel
          </Button>

          <Button
            className={styles.modal__confirm_btn}
            color="green"
            size="lg"
            onClick={confirm}
            loading={isConfirming}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default InstorePickups;
