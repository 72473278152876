import {useHistory} from 'react-router';

import SearchInput from 'components/SearchInput';
// import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
// import Typography from 'components/Typography';
// import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {DataItem} from 'types';
import styles from './Requests.module.scss';
// import stat_time from '../DeliveryPlanner/icons/stat_time.svg';
// import calendar from '../DeliveryPlanner/icons/calendar.svg';
import {DataTableSchemaItem} from 'types';
import useRequests from './useRequests';
import {useCallback, useEffect, useState} from 'react';
import Pagination from 'components/Pagination';
import {useRouteMatch} from 'react-router-dom';
// import DateTimePicker from 'components/DateTimePicker';
// import Select from 'components/Select';
// import {components} from 'react-select';
// import {groupBy, isArray, map, reject, sortBy as sortLodash} from 'lodash';
// import nyPostalCodeBoroughOptions from 'shared/ny-postal-code-borough-options';
// import locationIcon from './location.svg';
import Button from 'components/Button';
import RefillHandler from './RefillHandler';

const MM_SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'rx_no', header: 'Rx #', sortable: true, index: 0},
  {dataKey: 'customer', header: 'Patient', sortable: true, index: 1},
  {dataKey: 'status', header: 'Status', sortable: true, index: 2},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false, index: 3},
  {dataKey: 'amount_due', header: 'Amount', sortable: false, index: 4},
  {
    dataKey: 'number',
    header: 'Order #',
    sortable: true,
    align: 'center',
    index: 5,
  },
  {
    dataKey: 'refill',
    header: 'Refill',
    align: 'center',
    index: 6,
  },
  // {
  //   dataKey: 'sync_time',
  //   header: 'Sync time',
  //   sortable: true,
  //   align: 'center',
  //   index: 6,
  // },
  // {dataKey: 'bill_time', header: 'Bill Time', sortable: false, index: 7},
  // {
  //   dataKey: 'preference_date',
  //   header: 'Preference date',
  //   sortable: false,
  //   index: 8,
  // },
  // {dataKey: 'view', header: '', align: 'right'},
];

// const locationOptionsGrouped = groupBy(nyPostalCodeBoroughOptions, 'label');
// const sorted: {label: string; value: any}[] = map(
//   locationOptionsGrouped,
//   (obj, key) => ({
//     label: key,
//     value: map(obj, 'value'),
//   })
// );

// const locationOptions = sortLodash(sorted, 'label');

type Props = {
  source: string;
};

function Requests({source}: Props) {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data,
    totalPages,
    // dailyCount,
    loading,
    sortBy,
    sortOrder,
    onSort,
    // status,
    // setStatus,
    keyWord,
    setKeyWord,
    // MMtabs,
    // onChangeData,
    // onChangeLocation,
    // date,
    // location,
    // setPreferenceTime,
    // preferenceTime,
    // setOnlyOTC,
    // onlyOTC,
    // paid,
    // setPaid,
    refillRef,
    refetch,
  } = useRequests({page: currentPage - 1, source});
  const history = useHistory();

  const [MMTable, setMMTable] = useState(
    MM_SCHEMA.map((item) => ({
      ...item,
      value: item.dataKey,
      label: item.header,
    }))
  );

  const getLocalTable = () => {
    let localTable = localStorage.getItem('tableData');
    if (localTable) {
      const data: any[] = MM_SCHEMA.filter((item) =>
        JSON.parse(localTable || '').find(
          (el: any) => el.dataKey === item.dataKey
        )
      );
      setMMTable(
        data.map((item) => ({...item, value: item.dataKey, label: item.header}))
      );
    }
  };

  useEffect(() => {
    getLocalTable();
  }, []);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (source === 'app') {
        history.push(`/requests/${row.id}`);
      } else {
        history.push(`/orders/${row.id}`);
      }
    },
    [history, source]
  );

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  // const onChangeTab = (val: any) => {
  //   if (val) {
  //     const newTabs = MMTable.some((item) => item.label === val.label)
  //       ? reject(MMTable, val)
  //       : [...MMTable, val];

  //     setMMTable(sortLodash(newTabs, 'index'));

  //     localStorage.setItem(
  //       'tableData',
  //       JSON.stringify(sortLodash(newTabs, 'index'))
  //     );
  //   }
  // };

  return (
    <>
      <div className={styles.top_header}>
        <SearchInput
          className={styles.input}
          value={keyWord}
          onChange={(val) => {
            setKeyWord(val.target.value);
            setCurrentPage(1);
            if (params.page !== '1') {
              history.push('/prescriber/prescriptions');
            }
            sessionStorage.setItem(`searchKey_${source}`, val.target.value);
          }}
          placeholder="Search"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 6,
          }}
        >
          <Button
            onClick={() => history.push('/prescriber/prescriptions-create')}
            color="blue"
          >
            Add Prescription
          </Button>
          {/* <Typography variant="h4">
            Today’s Prescriptions:
            {dailyCount}
          </Typography> */}
        </div>
      </div>
      <ListWrapper>
        {/* <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={MMtabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            <Select
              className={'DeliveryPlanner__Filter'}
              placeholder="Location"
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !location ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => {
                  return (
                    <components.Control {...props}>
                      <img
                        className={styles.locationIcon}
                        src={locationIcon}
                        alt="location_icon"
                      />
                      {children}
                    </components.Control>
                  );
                },
                SingleValue: ({children, ...props}) => {
                  return (
                    <components.SingleValue {...props}>
                      {isArray(props.selectProps.value)
                        ? props.selectProps.value
                            .map((item: {label: string}) => item.label)
                            .join(', ')
                        : ''}
                    </components.SingleValue>
                  );
                },
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              onChange={onChangeLocation}
              isClearable
              options={[
                {
                  label: 'Locations',
                  options: locationOptions,
                },
              ]}
              value={location}
            />
            {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setOnlyOTC(val)}
                options={[
                  {label: 'OTC', value: 'true'},
                  {label: 'Rx', value: 'false'},
                ]}
                value={onlyOTC}
                placeholder={
                  <span className="select-placeholder-text">OTC/Rx</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !onlyOTC ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
            {source === 'micromerchant' && (
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setPaid(val)}
                options={[
                  {label: 'Paid', value: 'true'},
                  {label: 'Unpaid', value: 'false'},
                ]}
                value={paid}
                placeholder={
                  <span className="select-placeholder-text">Paid/Unpaid</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !paid ? <components.DropdownIndicator {...props} /> : null,
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          </div>
          {source === 'micromerchant' && (
            <div className={styles.preferenceDate}>
              <DateTimePicker
                icon={calendar}
                showTimeSelect={false}
                dateFormat="MM/dd/yy E"
                value={date}
                placeholder="Pr. date"
                customInput={styles.customInput}
                onChange={onChangeData}
              />
              <Select
                className={styles.selectCon}
                isSearchable={false}
                onChange={(val: any) => setPreferenceTime(val)}
                options={[
                  {label: 'Morning', value: 'morning'},
                  {label: 'Afternoon', value: 'afternoon'},
                  {label: 'Evening', value: 'evening'},
                ]}
                value={preferenceTime}
                placeholder={
                  <span className="select-placeholder-text">Pr. time</span>
                }
                isClearable
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  Control: ({children, ...props}) => (
                    <components.Control {...props} className="select-row">
                      <img className={'timer'} src={stat_time} alt="timer" />
                      {children}
                    </components.Control>
                  ),

                  IndicatorSeparator: () => null,
                }}
              />
              <Select
                className={'Table_Select'}
                isSearchable={false}
                onChange={onChangeTab}
                options={[
                  {
                    label: 'Table column',
                    options: MM_SCHEMA.map((item) => ({
                      ...item,
                      value: item.dataKey,
                      label: item.header,
                    })),
                  },
                ]}
                value={MMTable}
                placeholder={
                  <span className="select-placeholder-text">Display</span>
                }
                components={{
                  Input: () => null,
                  DropdownIndicator: (props) =>
                    !preferenceTime ? (
                      <components.DropdownIndicator {...props} />
                    ) : null,
                  SingleValue: ({children, ...props}) => {
                    return (
                      <components.SingleValue {...props}>
                        Display
                      </components.SingleValue>
                    );
                  },
                  IndicatorSeparator: () => null,
                }}
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </ListActionsWrapper> */}
        <DataTable
          schema={MMTable}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && totalPages > 1 && (
          <Pagination
            basePath={'/prescriber/prescriptions'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
        <RefillHandler refetch={refetch} ref={refillRef} />
      </ListWrapper>
    </>
  );
}

export default Requests;
